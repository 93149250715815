import { QueueTypeS } from "@ugg/shared/utils/queue-type-helpers";

export function getTierListPickRateCutoff(queueType?: string) {
  // Changed from 1.0% -> 0.5%
  if (queueType === QueueTypeS.ONE_FOR_ALL) {
    return 0;
  }
  return 0.5;
}

export function getTier(winrate: number) {
  if (winrate < 45) {
    return "D";
  } else if (winrate < 48.5) {
    return "C";
  } else if (winrate < 51.5) {
    return "B";
  } else if (winrate < 53) {
    return "A";
  } else if (winrate < 55) {
    return "S";
  } else {
    return "S+";
  }
}

export function getTierColor(winrate: number) {
  if (isNaN(winrate)) {
    return "";
  }

  if (winrate < 45) {
    return "shinggo-tier";
  } else if (winrate < 48.5) {
    return "meh-tier";
  } else if (winrate < 51.5) {
    return "okay-tier";
  } else if (winrate < 53) {
    return "good-tier";
  } else if (winrate < 55) {
    return "great-tier";
  } else {
    return "volxd-tier";
  }
}

export function getTierColorHex(winrate: number, defaultColorHex = "#FFFFFF") {
  if (isNaN(winrate)) {
    return "";
  }

  if (winrate < 45) {
    return "#FF4E50";
  } else if (winrate < 48.5) {
    return "#FCB1B2";
  } else if (winrate < 51.5) {
    return defaultColorHex;
  } else if (winrate < 53) {
    return "#7EA4F4";
  } else if (winrate < 55) {
    return "#3273FA";
  } else {
    return "#FF9B00";
  }
}

export function getLuTier(stdevs: number) {
  if (stdevs >= 2) {
    return "S+";
  } else if (stdevs >= 0.75) {
    return "S";
  } else if (stdevs >= 0) {
    return "A";
  } else if (stdevs >= -0.5) {
    return "B";
  } else if (stdevs >= -0.75) {
    return "C";
  } else {
    return "D";
  }
}

export function getLuTierColor(stdevs: number) {
  if (stdevs >= 2) {
    return "volxd-tier";
  } else if (stdevs >= 0.75) {
    return "great-tier";
  } else if (stdevs >= 0) {
    return "good-tier";
  } else if (stdevs >= -0.5) {
    return "okay-tier";
  } else if (stdevs >= -0.75) {
    return "meh-tier";
  } else {
    return "shinggo-tier";
  }
}

export function getLuTierColorHex(stdevs: number) {
  if (stdevs >= 2) {
    return "#FF9B00";
  } else if (stdevs >= 0.75) {
    return "#3273FA";
  } else if (stdevs >= 0) {
    return "#7EA4F4";
  } else if (stdevs >= -0.5) {
    return "#FFFFFF";
  } else if (stdevs >= -0.75) {
    return "#FCB1B2";
  } else {
    return "#FF4E50";
  }
}
