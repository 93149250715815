import React, { Component, Fragment } from "react";
import { useLocation, Link } from "react-router-dom";
import { useGlobal } from "reactn";
import { useUGGPatches } from "@ugg/shared/api/requests/patches";
import { TierChampionPlaceholder } from "./ChampionPlaceholderLP";
import { numberWithCommas } from "@ugg/shared/utils/math";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { getLuTierColor, getLuTier, getTierColor } from "@ugg/shared/utils/tier-helpers";
import { normalizeRole } from "@ugg/shared/utils/role-helpers";
import { ROLE_OPTIONS } from "@ugg/shared/query-params/filter-options/common";

const GridBlock = (props) => {
  const { role, classNames, children } = props;

  let roleLabel = normalizeRole(role);
  const foundRole = ROLE_OPTIONS.find((roleOption) => {
    return roleOption.value === roleLabel;
  });

  return (
    <div>
      <div className={`grid-block`}>
        <div className="grid-block-content">
          <Link className="grid-block-header" to={{ pathname: `/lol/tier-list/`, search: `?role=${foundRole.value}` }}>
            <div className="title">
              <div className="role-icon">{foundRole.img}</div>
              <h2>{foundRole.label}</h2>
            </div>
          </Link>
          {children}
        </div>
      </div>
    </div>
  );
};

const TierChampion = (props) => {
  const { getChampionName, getChampionImg, getNormalizedChampionName } = getRiotAssetsContext();
  const { champion_id: championId, tier, role, win_rate: winRate, matches } = props.tierData || {};

  return (
    <div className="tier-champion">
      <Link
        className="champion-link"
        to={{
          pathname: `/lol/champions/${getNormalizedChampionName(championId)}/build`,
          search: `?role=${role}`,
        }}
      >
        <div className="champion-wrapper">
          <div className="champion-image">
            <img src={getChampionImg(championId)} />
          </div>
          <div className={`champion-tier ${getLuTierColor(tier.stdevs)}`}>{getLuTier(tier.stdevs)}</div>
        </div>
        <div className="champion-name">{getChampionName(championId)}</div>
        <div className="horizontal-line" />
        <div className="champion-stats">
          <div className={`win-rate ${getTierColor(winRate)}`}>
            <strong>{`${Math.round(winRate * 100) / 100}%`}</strong>
          </div>
          <div>
            {numberWithCommas(matches)}&nbsp;
            <span className="grey-text">Matches</span>
          </div>
        </div>
        <div className="view-btn">VIEW</div>
      </Link>
    </div>
  );
};

const TierListLP = (props) => {
  const location = useLocation();
  const [windowBreakpoint] = useGlobal("responsive");
  const { data: patches } = useUGGPatches();
  const { fetchingData, tierData, keystoneData } = props;

  const displayChampions = (champions) => {
    let list = [];
    if (fetchingData || !tierData) {
      list = [...Array(3)].map((placeholder, index) => <TierChampionPlaceholder key={index} />);
    } else {
      list = champions.map((champion, index) => {
        return <TierChampion key={index} tierData={champion} keystoneData={keystoneData} />;
      });
    }
    return <div className="champion-list">{list}</div>;
  };

  const data =
    tierData &&
    tierData
      .filter((champion) => {
        return champion.tier.pick_rate > 1;
      })
      .sort((a, b) => {
        return b.tier.stdevs - a.tier.stdevs;
      });

  let mid = [],
    top = [],
    adc = [],
    jungle = [],
    supp = [];

  data &&
    data.forEach((champion) => {
      if (champion.role === "mid") mid.push(champion);
      else if (champion.role === "top") top.push(champion);
      else if (champion.role === "adc") adc.push(champion);
      else if (champion.role === "jungle") jungle.push(champion);
      else if (champion.role === "support") supp.push(champion);
    });

  const championMax = windowBreakpoint === "MOBILE_SMALL" || windowBreakpoint === "MOBILE_MEDIUM" ? 3 : 5;

  return (
    <div className="tier-homepage-container">
      <div className="content-title">
        <div className="left-title">
          <div className="blue-bar-left" />
          <strong>U.GG Solo Queue Tier List</strong>
        </div>
        <div className="patch">{`Patch ${patches[0].replace(/\.|\_/g, ".")}`}</div>
      </div>
      <div className="tier-grid">
        <GridBlock role="top">{displayChampions(top.slice(0, championMax))}</GridBlock>
        <GridBlock role="jungle">{displayChampions(jungle.slice(0, championMax))}</GridBlock>
        <GridBlock role="mid">{displayChampions(mid.slice(0, championMax))}</GridBlock>
        <GridBlock role="adc">{displayChampions(adc.slice(0, championMax))}</GridBlock>
        <GridBlock role="support">{displayChampions(supp.slice(0, championMax))}</GridBlock>
      </div>
      <div className="tier-show-more">
        <Link className="blue-btn" to={{ pathname: `/lol/tier-list/` }}>
          <h1>Show Full Tier List</h1>
        </Link>
      </div>
    </div>
  );
};

export default TierListLP;
